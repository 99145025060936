export const finYears = [
  "2024",
  "2023",
  "2022",
  "2021",
  "2020",
];

export const assetObjs = [
  { Index: ["all", "India", "US", "Brazil"] },
  { Commodities: ["all", "India", "US", "Binance"] },
  { "Equities Option": ["India"] },
  { Stock: ["India"] },
];
export const regionObjs = [
  {
    India: [
      "Stock",
      "Index",
      "Equities Option",
      "Commodities",
    ],
  },
  { US: ["Index", "Commodities"] },
  { Brazil: ["Index"] },
  { Binance: ["Commodities"]},
];

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const redValues = ["maxDrawdown", "mdd", "negativeDays"];
export const greenValues = ["tv", "positiveDays", "roc"];

export const dateFilter = ["date", "mtmtv", "startDate", "endDate"];
export const textFilter = [
  "strategy",
  "subStrategy",
  "month",
  "client",
  "asset",
  "region",
];
